import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getPostBySlug } from '../../services/blogService';

const ArticlePage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPost = async () => {
      try {
        const postData = await getPostBySlug(slug);
        if (!postData) {
          navigate('/blog');
          return;
        }
        setPost(postData);
      } catch (error) {
        console.error('Error loading post:', error);
        navigate('/blog');
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [slug, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <div className="container mx-auto px-4 py-8">
          <div className="animate-pulse">Chargement de l'article...</div>
        </div>
      </div>
    );
  }

  if (!post) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      <div className="container mx-auto px-4 py-8">
        <article className="max-w-4xl mx-auto">
          <header className="mb-12 text-center">
            <h1 className="text-5xl font-bold mb-6 text-blue-400">{post.title}</h1>
            <div className="text-gray-400">
              <time dateTime={post.date}>
                {new Date(post.date).toLocaleDateString('fr-FR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </time>
              {post.author && <span> • Par {post.author}</span>}
            </div>
          </header>

          <div className="prose prose-invert prose-blue max-w-none">
            <ReactMarkdown 
              remarkPlugins={[remarkGfm]}
              className="text-gray-300"
              components={{
                h1: ({node, ...props}) => <h1 className="text-4xl font-bold mb-6 text-blue-400" {...props} />,
                h2: ({node, ...props}) => <h2 className="text-3xl font-bold mb-4 text-blue-400" {...props} />,
                h3: ({node, ...props}) => <h3 className="text-2xl font-bold mb-3 text-blue-400" {...props} />,
                a: ({node, ...props}) => <a className="text-blue-400 hover:text-blue-300" {...props} />,
                code: ({node, ...props}) => <code className="bg-gray-700 rounded px-1 py-0.5" {...props} />,
                pre: ({node, ...props}) => <pre className="bg-gray-700 rounded p-4 overflow-x-auto" {...props} />
              }}
            >
              {post.content}
            </ReactMarkdown>
          </div>

          <footer className="mt-12 pt-6 border-t border-gray-700">
            <button
              onClick={() => navigate('/blog')}
              className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
            >
              ← Retour aux articles
            </button>
          </footer>
        </article>
      </div>
    </div>
  );
};

export default ArticlePage; 