import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthModal from '../../common/AuthModal/AuthModal';

function Header({ user, onLogout }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <header className="absolute w-full z-30">
        <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/" className="text-white font-bold">
                Valeur Intrinsèque
              </Link>
              <div className="hidden sm:flex ml-8 space-x-6">
                <Link to="/" className="text-gray-300 hover:text-white">
                  Calculateur
                </Link>
                <Link to="/blog" className="text-gray-300 hover:text-white">
                  Blog
                </Link>
              </div>
            </div>
            
            <div className="flex items-center space-x-4">
              {user ? (
                <>
                  <span className="text-gray-300">{user.displayName}</span>
                  <button
                    onClick={onLogout}
                    className="px-4 py-2 text-sm font-medium text-white bg-sky-600 rounded-md hover:bg-sky-700"
                  >
                    Déconnexion
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setShowModal(true)}
                  className="px-4 py-2 text-sm font-medium text-white bg-sky-600 rounded-md hover:bg-sky-700"
                >
                  Connexion
                </button>
              )}
            </div>
          </div>
        </nav>
      </header>

      <AuthModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={(user) => {
          setShowModal(false);
          // Mettre à jour l'état utilisateur dans App.js
          if (user) {
            localStorage.setItem('user', JSON.stringify(user));
          }
        }}
      />
    </>
  );
}

export default Header; 