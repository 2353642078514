// Articles statiques
const ARTICLES = [
  {
    slug: 'premier-article',
    title: 'Premier Article',
    date: '2024-01-17',
    author: 'Louis',
    description: 'Un exemple d\'article pour tester le nouveau système de blog basé sur Markdown',
    content: `# Premier Article

Bienvenue sur le nouveau système de blog basé sur Markdown ! Cette approche est beaucoup plus légère et plus rapide que Strapi.

## Avantages

1. **Performance** : pas de base de données, juste des fichiers texte
2. **Simplicité** : édition directe dans GitLab
3. **Versioning** : historique complet des modifications
4. **Rapidité** : déploiement instantané

## Comment ça marche ?

Les articles sont écrits en Markdown, un format texte simple qui permet de :

- Créer des titres
- Faire des listes
- Ajouter des *italiques* et du **gras**
- Insérer des [liens](https://example.com)
- Et bien plus encore !`
  },
  {
    slug: 'comprendre-valeur-intrinseque',
    title: 'Comprendre la Valeur Intrinsèque',
    date: '2024-01-17',
    author: 'Louis',
    description: 'Une exploration approfondie du concept de valeur intrinsèque et son importance dans l\'investissement',
    content: `# Comprendre la Valeur Intrinsèque

La valeur intrinsèque est un concept fondamental en investissement. Mais que signifie-t-elle réellement ?

## Définition

La valeur intrinsèque représente la valeur réelle d'une entreprise, indépendamment de son prix de marché actuel. Elle prend en compte :

- Les actifs de l'entreprise
- Les flux de trésorerie futurs
- La position concurrentielle
- La qualité de la gestion

## Pourquoi est-ce important ?

L'écart entre le prix de marché et la valeur intrinsèque crée des opportunités d'investissement. Comme le disait Warren Buffett :

> "Le prix est ce que vous payez. La valeur est ce que vous obtenez."

## Comment la calculer ?

Plusieurs méthodes existent :

1. **Actualisation des flux de trésorerie (DCF)**
2. **Analyse des actifs nets**
3. **Comparaison avec les pairs**

Notre calculateur vous aide à estimer cette valeur de manière simple et efficace.`
  },
  {
    slug: 'investissement-long-terme',
    title: 'L\'Art de l\'Investissement à Long Terme',
    date: '2024-01-17',
    author: 'Louis',
    description: 'Les principes fondamentaux pour réussir ses investissements sur le long terme',
    content: `# L'Art de l'Investissement à Long Terme

L'investissement à long terme est souvent considéré comme la stratégie la plus fiable pour créer de la richesse.

## Les Principes Clés

### 1. La Patience est Cruciale

Le temps est votre meilleur allié en investissement. Les effets des intérêts composés sont souvent sous-estimés.

### 2. La Diversification

Ne mettez pas tous vos œufs dans le même panier :

- Actions
- Obligations
- Immobilier
- Autres actifs alternatifs

### 3. La Discipline

Restez fidèle à votre stratégie, même dans les moments difficiles. Comme le dit Charlie Munger :

> "La meilleure façon de devenir riche est d'être patient."

## Erreurs Courantes à Éviter

1. **Timing du marché** : Impossible à réaliser de manière consistante
2. **Suivre la foule** : Les modes passent, les fondamentaux restent
3. **Négliger les frais** : Ils peuvent significativement impacter vos rendements

Investir est un marathon, pas un sprint. La clé est de rester concentré sur le long terme.`
  }
];

// Fonction pour récupérer tous les articles
export const getAllPosts = async () => {
  return ARTICLES;
};

// Fonction pour récupérer un article spécifique
export const getPostBySlug = async (slug) => {
  return ARTICLES.find(article => article.slug === slug) || null;
}; 