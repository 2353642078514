import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllPosts } from '../../services/blogService';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        console.log('Début du chargement des articles...');
        const allPosts = await getAllPosts();
        console.log('Articles reçus:', allPosts);
        setPosts(allPosts);
      } catch (error) {
        console.error('Error loading posts:', error);
      } finally {
        console.log('Fin du chargement, état de loading mis à false');
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  console.log('État actuel - loading:', loading, 'posts:', posts);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <div className="container mx-auto px-4 py-8">
          <div className="animate-pulse">Chargement des articles...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-12 text-center">Articles</h1>
        {posts.length === 0 ? (
          <div className="text-center text-gray-400">Aucun article disponible</div>
        ) : (
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.slug} className="bg-gray-800 rounded-lg shadow-xl overflow-hidden transform hover:scale-105 transition-transform duration-200">
                <div className="p-6">
                  <h2 className="text-2xl font-semibold mb-3 text-blue-400">
                    <Link to={`/blog/${post.slug}`} className="hover:text-blue-300">
                      {post.title}
                    </Link>
                  </h2>
                  <div className="text-gray-400 text-sm mb-4">
                    <span>{new Date(post.date).toLocaleDateString('fr-FR')}</span>
                    {post.author && (
                      <span> • Par {post.author}</span>
                    )}
                  </div>
                  <p className="text-gray-300 mb-4">{post.description}</p>
                  <Link
                    to={`/blog/${post.slug}`}
                    className="inline-block px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200"
                  >
                    Lire l'article →
                  </Link>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog; 